<template>
  <teleport to="head">
    <title>
      УКЛАДКА ТРОТУАРНОЙ ПЛИТКИ
    </title>
  </teleport>
  <main class="main page__view">
    <h2 class="header-text">
      УКЛАДКА ТРОТУАРНОЙ ПЛИТКИ: ЦЕНА ЗА КВАДРАТНЫЙ МЕТР
    </h2>
    <p>
      Предлагаем монтаж фигурных элементов мощения (фэм) с подготовкой
      поверхности. Ведем работы по грунту и по бетону, тщательно соблюдая
      технологию. При заказе работ под ключ цена за квадратный метр укладки
      тротуарной плитки включает затраты на материал с доставкой и оплату услуг
      рабочим.
    </p>
    <iframe
      width="100%"
      height="500"
      src="https://www.youtube.com/embed/1kHPHwZaGrE"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <ServiceCardList :services="services" />

    <h2 class="header-text">ПРИМЕРЫ НАШИХ РАБОТ</h2>
    <ImageList :images="ourWorks" />

    <h3 class="header-text">
      ОТ ЧЕГО ЗАВИСЯТ ЦЕНЫ НА УКЛАДКУ ТРОТУАРНОЙ ПЛИТКИ
    </h3>
    <p>
      Цена за работу включает в себя создание покрытия под ключ, а значит,
      учитывает трудоемкость всех подготовительных операций и затраты на
      необходимые материалы. Стоимость зависит от таких факторов:
    </p>
    <ul class="list">
      <li>
        Какой материал используется в основе - вибролитьевая тротуарная плитка
        или вибропрессованная;
      </li>
      <li>
        Нужны ли подготовительные выравнивающие работы на ландшафте, требуется
        ли расчистка территории;
      </li>
      <li>Сколько подстилающих слоев будет иметь основание;</li>
      <li>
        Насколько заглубляется бордюрный каменьи нуждается ли он в
        бетонировании;
      </li>
      <li>
        Как будет выполняться укладка – линейно, с однотонными модулями, или из
        фэм разных цветов, диагонально или со сложным рисунком, по схеме;
      </li>
      <li>Необходимы ли дополнительные элементы – водостоки и так далее.</li>
    </ul>
    <img
      alt="укладка основание"
      class="ulkadka__image"
      src="/images/ukladka-osnovanie.jpg"
    />
    <h3 class="header-text">КАК РАССЧИТАТЬ ЦЕНУ ЗА РАБОТУ</h3>
    <p>
      Сколько стоит укладка тротуарной плитки в Санкт-Петербурге? Мы предлагаем
      заказать монтаж под ключ: наш специалист выполнит все необходимые замеры,
      рассчитает количество материала и организует доставку. В этом случае цена
      на укладку тротуарной плитки за м2 будет минимальной, ведь вам не придется
      обращаться к посредникам, не понадобится искать ни грузчиков, ни
      строителей. Позвоните, чтобы пригласить специалиста. Наши замерщики
      выезжают по всей Ленинградской области, и эта услуга всегда бесплатна для
      наших клиентов.
    </p>
    <h2 class="header-text">
      ПОЧЕМУ ВЫГОДНО ЗАКАЗЫВАТЬ МОНТАЖ В НАШЕЙ КОМПАНИИ
    </h2>
    <ol class="list">
      <li>
        Доступная цена. Выгодные скидки для постоянных клиентов и при заказе
        большого объема.
      </li>
      <li>
        Большой опыт работы на рынке благоустройства. Наши мастера справятся
        даже со сложным дизайном и тщательно соблюдают все пожелания по рисунку
        и комбинации цветов.
      </li>
      <li>Мы сами производим фэм и гарантируем высокое качество материалов.</li>
      <li>
        Перед началом работ выполняем все необходимые расчеты, чтобы вы получили
        долговечную дорожку или площадку, соответствующую вашим требованиям по
        качеству.
      </li>
    </ol>
    <p>
      В нашем портфолио – десятки частных дворов и муниципальные объекты. Все
      они эстетичны и надежны. Уложенные поверхности не коробит с течением
      времени, они не трескаются и не расползаются. Сохраняют целостность при
      активной эксплуатации и резких температурных перепадах.
    </p>
  </main>
</template>

<script>
import ServiceCardList from '@/components/ServiceCardList.vue';
import ImageList from '@/components/ImageList.vue';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      ourWorks: [
        '/images/our-works/photo_2021-03-03_19-29-04.jpg',
        '/images/our-works/photo_2021-03-03_19-29-17.jpg',
        '/images/our-works/photo_2021-03-03_19-29-22.jpg',
        '/images/our-works/photo_2021-03-03_19-29-32.jpg',
        '/images/our-works/photo_2021-03-03_19-29-35.jpg',
        '/images/our-works/photo_2021-03-03_19-29-38.jpg',
        '/images/our-works/photo_2021-03-03_19-29-40.jpg',
        '/images/our-works/photo_2021-03-03_19-29-42.jpg',
        '/images/our-works/photo_2021-03-03_19-29-45.jpg',
        '/images/our-works/photo_2021-03-03_19-29-52.jpg',
        '/images/our-works/photo_2021-03-03_19-29-55.jpg',
        '/images/our-works/photo_2021-03-03_19-29-57.jpg',
      ],
    };
  },
  components: {
    ServiceCardList,
    ImageList,
  },
  computed: {
    ...mapGetters(['getServicesByIdList']),
    services() {
      const localServices = [
        {
          id: 1,
          title: 'УКЛАДКА ПОД АВТОМОБИЛЬ',
          price: '3500',
          additionalInfo: 'за м2',
          mainImage: '/images/services/ukladka-avto.png',
        },
      ];
      return this.getServicesByIdList([1, 8, 10, 6, 2, 11, 9]).concat(
        localServices
      );
    },
  },
};
</script>

<style scoped>
.main {
  display: grid;
  grid-row-gap: 20px;
}

.list {
  padding-left: 30px;
}

.ulkadka__image {
  width: 100%;
  height: 400px;
}
</style>
