
<template>
  <teleport to="head">
    <title>
      Интернет магазин завода по производству тротуарной плитки
    </title>
  </teleport>
  <section class="banner-container">
    <div class="banner page__view">
      <h1 class="banner__header-text bnhd">Вибропрессованная тротуарная плитка</h1>

      <div class="countdown">
        <div class="timer">
          <span>{{ days }}<small>дн</small></span> :
          <span>{{ hours }}<small>час</small></span> :
          <span>{{ minutes }}<small>мин</small></span> :
          <span>{{ seconds }}<small>сек</small></span>
        </div>
      </div>

      <h1 class="banner__header-text">
        Рассчитаем стоимость заказа с доставкой за 10 минут и предложим лучшую цену
      </h1>

      <div class="icons-container">
        <a href="tel:+79912630683" class="icon-link">
          <FontAwesomeIcon icon="phone-alt" class="telephone_icon animated-icon" />
        </a>
        <a href="https://wa.me/+79912630683" class="icon-link">
          <FontAwesomeIcon
            class="whatsapp_icon"
            icon="whatsapp"
            type="fab"
          />
        </a>
      </div>
    </div>
  </section>
  <div class="banner_page">
      <div class="banner_editblock">
        <div class="banner_text_block">
            <span class="banner_text"> <strong> Укладка плитки по Москве и МО.Выезд на замер <br> БЕСПЛАТНО <br> </strong>
              Работа под ключ с Гарантией 5 года
            </span> 
          </div>
      </div>
  </div>
  <main class="main page__view">

    <Ukladka/>

    <h1 class="header-text">Наши преимущества</h1>
    <section class="our-advantagess">
      <div class="advantage">
        <FontAwesomeIcon class="advantage__icon" icon="tags" />
        <h3 class="advantage__title">Цены производителя</h3>
        <p class="advantage__description">
          Мы не создаем наценок, а предлагаем товар по стоимости,
          соответствующей его качеству.
        </p>
      </div>

      <div class="advantage">
        <FontAwesomeIcon class="advantage__icon" icon="clock" />
        <h3 class="advantage__title">Оперативность</h3>
        <p class="advantage__description">
          Большинство заказов мы выполняем в течение 24 часов с момента
          обращения.
        </p>
      </div>

      <div class="advantage">
        <FontAwesomeIcon class="advantage__icon" icon="download" />
        <h3 class="advantage__title">
          Укладка с гарантией
        </h3>
        <p class="advantage__description">
          Мы предоставляем гарантию на любой вариант монтажа тротуарной плитки.
        </p>
      </div>
    </section>

    <h1 class="header-text">ПОПУЛЯРНЫЕ ТОВАРЫ</h1>
    <p class="secondary-text">
      Мы сами занимаемся производством, продажей и доставкой всех представленных
      в ассортименте изделий. Товары производятся на собственных линиях, что
      позволяет компании «Ореховское» гарантировать высочайшее качество
      изготовления. Поскольку мы практически независимы от поставщиков, у нас
      можно купить тротуарную плитку по выгодной, доступной заказчику цене!
    </p>

    <ProductCardList :products="products" />
  </main>
</template>

<script>
import ProductCardList from '@/components/ProductCardList.vue';
import Ukladka from '@/views/Ukladka.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      products: (state) => state.products.slice(0, 9),
    }),
  },
  components: { ProductCardList, Ukladka,FontAwesomeIcon },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      targetDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // Countdown for 7 days
    };
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const diff = this.targetDate - now;

      this.days = Math.floor(diff / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      this.minutes = Math.floor((diff / 1000 / 60) % 60);
      this.seconds = Math.floor((diff / 1000) % 60);
    },
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
  },
};

</script>

<style scoped>
.header-text {
  font-family: 'Open Sans', Arial, sans-serif;
  color: #121214;
  text-align: center;
  font-weight: lighter;
  line-height: 44px;
  margin: 44px 0;
}
.secondary-text {
  color: #777777;
  text-align: center;
  font-size: 18px;
}
.secondary-text__link {
  color: #0088cc;
  outline: none;
}

.banner-container {
  background: url('../assets/fon_header.jpg') no-repeat center/cover fixed;
}
.banner {
  padding: 150px 0;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.countdown {
  margin: 20px 0;
}

.timer {
  display: flex;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  font-size: 2rem; 
  color: #a61717; 
  margin-top: 20px; 
  align-items: center;
}

.timer span {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px; 
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  min-width: 60px; 
  color: #a61717;
}

.timer span small {
  display: block;
  font-size: 0.75rem;
  margin-top: 5px;
  color: #a61717; 
}
.timer span:nth-child(odd) {
  font-weight: bold; 
}

.timer span:hover {
  background-color: rgba(255, 255, 255, 0.1); 
  transform: scale(1.05); 
  transition: transform 0.3s, background-color 0.3s;
}


.icons-container {
  display: flex;
  justify-content: center;
  /* gap: 20px; */
  margin-top: 30px;
  
}

.icon-link {
  display: inline-block;
}

.telephone_icon, .whatsapp_icon {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  color: white;
  font-size: 30px;
  
}

.telephone_icon {
  background-color: #a61717;
  
}

.whatsapp_icon {
  background-color: #84c312;
}

.animated-icon {
  animation: vibrate 0.9s infinite;
  transition: box-shadow 0.3s ease;
}
@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(2px, -2px);
  }
  60% {
    transform: translate(-2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

/* Optional additional hover effect */
.animated-icon:hover {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.9); /* Glow effect */
}
.banner_page{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  padding-top: 50px;
}

.banner_editblock{
  width:70vh;
  height: 230px;
  background: url(../../public/images/random_grey_variations.png);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.banner_text_block{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner_text{
  color: #c7c6c6;
  font-weight:bolder;
  font-size: 23px;
  text-align: center;
}
.banner__header-text {
  font-size: 35px;
  width: 70%;
  margin: 0 auto;
}
.bnhd {
  font-size: 40px;
}
@media (max-width: 410px) {
  .banner__header-text {
    font-size: 1.6em;
  }
}

.products {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
  row-gap: 40px;
}
@media (max-width: 1000px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 730px) {
  .products {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Our advantagess */
.our-advantagess {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

@media (max-width: 850px) {
  .our-advantagess {
    grid-template-columns: 1fr;
  }
}

.advantage {
  text-align: center;
}

.advantage__title {
  color: var(--primary-color);
}

.advantage__icon {
  background-color: var(--primary-color);
  border-radius: 35px;
  color: #fff;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 30px;
  padding: 5px;
}
</style>