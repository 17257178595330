<template>
  <article class="ProductCard">
    <router-link :to="`/product/${product.id}`">
      <div class="product__image-container">
        <img
          class="product__image"
          :src="product.images[0]"
          :alt="product.name + ' изображение'"
        />
      </div>
    </router-link>
    <div class="product__body">
      <h3 class="product__name">
        <router-link :to="`/product/${product.id}`" class="product__link">
          {{ product.name }}
        </router-link>
      </h3>
      <p class="product__info">
        {{ product.info }}
      </p>
      <!-- <button class="product__buy">
        <FontAwesomeIcon icon="shopping-cart" />
      </button> -->
    </div>
  </article>
</template>

<script>
export default {
  props: ['product'],
};
</script>

<style scoped>
.ProductCard {
  width: 100%;
  border: 3px solid #515151;
  overflow: hidden;
  border-radius: 5px;
}

.product__body {
  display: grid;
  row-gap: 10px;
  padding: 16px 8px;
  background-image: url(/images/random_grey_variations.png);
  color: white;
}

/* ProductCard Name */

.product__link {
  color: inherit;
  text-decoration: none;
}

.product__image-container {
  overflow: hidden;
  cursor: pointer;
  height: 250px;
}

.product__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product__image:hover {
  transform: scale(1.2);
  transition: transform 0.4s;
}

/* .product__buy {
  justify-self: right;
  width: 35px;
  padding: 5px;
  display: flex;
  background-color: var(--primary-color);
  border: 1px solid white;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.product__buy:hover {
  color: var(--primary-color);
  background-color: white;
} */
</style>
