<template>
  <div class="service">
    <img :src="service.mainImage" class="service__image" :alt="service.title" />
    <h4 class="service__title header-text">
      {{ service.title }}
    </h4>
    <p class="service__price">от {{ service.price }} ₽</p>
    <p class="service__additional-info">
      {{ service.additionalInfo }}
    </p>
    <router-link :to="`/service/${service.id}`" class="service__see-more">
      Подробнее
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['service'],
};
</script>

<style scoped>
/* Service */
.service {
  background-image: url('/images/random_grey_variations.png');
  padding: 50px 10px;
  text-align: center;
  display: grid;
  row-gap: 15px;
  color: white;
  border-radius: 5px;
  height: 100%;
}

.service__image {
  width: 220px;
  height: 220px;
  justify-self: center;
}

.service__price {
  font-size: 1.6em;
}

.service__additional-info,
.service__see-more {
  font-size: 1.4em;
}

/* See More Button */
.service__see-more {
  width: 60%;
  justify-self: center;
  padding: 10px 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 4px;
  background-color: #a61717 ;
}
/* Service Image */
</style>
