<template>
  <ul class="product-card-list">
    <li class="product-card-item" v-for="(product, idx) of products" :key="idx">
      <ProductCard class="product-card" :product="product" />
    </li>
  </ul>
</template>


<script>
import ProductCard from '@/components/ProductCard.vue';
export default {
  props: ['products'],
  components: {
    ProductCard,
  },
};
</script>

<style scoped>
/* Products */
.product-card-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
  row-gap: 40px;
  list-style: none;
}
@media (max-width: 1000px) {
  .product-card-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 730px) {
  .product-card-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
