<template>
  <div class="images">
    <ul class="images__list">
      <li
        class="images__item"
        @click="showLightBox(idx)"
        v-for="(image, idx) of images"
        :key="idx"
      >
        <img
          class="item__image"
          alt="Наши работы по укладке тротуарной плитки"
          :src="image"
        />
        <div class="item__overlay">
          <fontAwesomeIcon class="item__overlay__icon" icon="search-plus" />
        </div>
      </li>
    </ul>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="lightbox.visible"
      :imgs="images"
      :index="lightbox.index"
      @hide="handleLightBoxHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  props: ['images'],
  data() {
    return {
      lightbox: {
        visible: false,
        index: 0, // default: 0
      },
    };
  },
  methods: {
    showLightBox(index) {
      this.lightbox.index = index;
      this.lightbox.visible = true;
    },
    handleLightBoxHide() {
      this.lightbox.visible = false;
    },
  },

  components: {
    VueEasyLightbox,
  },
};
</script>

<style scoped>
/* images__item */
.images__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 20px;
  list-style: none;
}

@media (max-width: 880px) {
  .images__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 650px) {
  .images__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* images__item */
.images__item {
  position: relative;
  cursor: pointer;
  height: 200px;
}

/* images__item Image */
.item__image {
  width: 100%;
  height: 100%;
}
.item__overlay {
  display: none;
  position: absolute;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .item__overlay {
    display: flex;
  }
}

.images__item:hover > .item__overlay {
  display: flex;
}

.item__overlay__icon {
  width: 35%;
}
</style>
