<template>
  <ul class="services__list">
    <li
      class="service services__item"
      v-for="service of services"
      :key="service.id"
    >
      <ServiceCard :service="service" />
    </li>
  </ul>
</template>

<script>
import ServiceCard from './ServiceCard.vue';
export default {
  props: ['services'],
  components: {
    ServiceCard,
  },
};
</script>

<style scoped>
.services__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media (max-width: 950px) {
  .services__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 680px) {
  .services__list {
    grid-template-columns: 1fr;
  }
}

.services__item {
  list-style: none;
}
</style>
